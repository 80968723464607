import React from "react";
import ourimpactIMG from "../../assets/images/ourimpactIMG.jpg";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import FeaturedCourses from "../../GenericComponents/FeaturedCourses";
import ComingSoon from "../ComingSoon";
import WebFrameList from "./Generic";

export default function Vue() {
  return (
    <div>
      {/* Start Breadcrumb 
    ============================================= */}
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{ backgroundImage: "url(assets/img/list/web-frame/11.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="py-5"></h1>
              <ul className="breadcrumb py-4">
                <li>
                  {/* <a href="#">
                    <i className="fas fa-home" /> Angular
                  </a> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
      {/* Star Services Details Area
    ============================================= */}
      <div className="services-details-area default-padding">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-lg-8 services-single-content">
                <h2>Understanding its Vue Tools and Ecosystem</h2>
                <ul>
                  <li>
                  Vue.js is a popular JavaScript framework that is flexible and easy to learn. Sometimes called, “the progressive JavaScript framework,” you can use Vue to enhance small webpages or use it to create large-scale enterprise applications.


                  </li>
                  <li>
                  Now, Vue.js is backed financially by the developers that use it through crowd funding. The project is now managed by an established group of talented engineers known as the “Vue.js Core Team.” These developers help maintain manage pull requests, maintain its stellar documentation, and help build tools, which is discussed in this book. With that said, the overall direction of the main Vue.js library is still very much under the direction of creator, Evan You.


                  </li>
                 
                </ul>
                <img src="assets/img/list/web-frame/12.png" alt="Thumb" />

                <h2>VueJS Ecosystem diagram</h2>

                <p className="text-primary font-weight-bold">
                Vue continues to grow in popularity and is rapidly being adopted by many developers, and Vue.js tools are popping up everywhere.
                </p>

                <p>
                Vue’s shallow learning curve, clear functionality-driven structure, and excellent documentation make it easy for novices to pick it up, and for more experienced developers to make a switch from other frameworks like React or Angular. If you are working with Vue, sooner or later you’ll get to use some fundamental tools and libraries which stand out from the crowd. Using them will level up your work scenario, and help you develop Vue based project with ease.

                </p>
                <img src="assets/img/list/web-frame/13.png" alt="Thumb" />
              </div>
              <div className="col-lg-4 services-sidebar">
                <WebFrameList />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Services Details Area */}
    </div>
  );
}

import React from 'react';
import {Route, Switch, BrowserRouter} from 'react-router-dom';
import ApplicationEntry from './components/ApplicationEntry';
import Router from './components/routes';

function App() {
  return (
    <BrowserRouter>
        <ApplicationEntry>
          <Router />
        </ApplicationEntry>
    </BrowserRouter>
  );
}

export default App;

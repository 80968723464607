import {Route, Switch, BrowserRouter} from 'react-router-dom';
import React from 'react';
import ScrollIntoView from "../../components/ScrollIntoView";

// single pages
import Home from '../Home';
import AboutUs from '../Pages/AboutUs';
import Contactus from '../Pages/Contactus';
import Services from '../Pages/Services';
import Blog from '../Pages/Blog';
import Careers from '../Pages/Careers';
import FAQ from '../Pages/FAQ';

// technical tab pages
import FullStackDevelopment from '../Pages/WebFramework/FullStackDevelopment';
import Angular from '../Pages/WebFramework/Angular';
import Vue from '../Pages/WebFramework/Vue';
import Python from '../Pages/WebFramework/Python';
import ReactJS from '../Pages/WebFramework/ReactJS';
import Ruby from '../Pages/WebFramework/Ruby';
import Wordpress from '../Pages/CMS/Wordpress';
import Drupal from '../Pages/CMS/Drupal';
import SquareSpace from '../Pages/CMS/SquareSpace';
import ReactNative from '../Pages/AppDevelopment/ReactNative';
import Flutter from '../Pages/AppDevelopment/Flutter';
import AndroidiOS from '../Pages/AppDevelopment/AndroidiOS';
import CloudOps from '../Pages/CloudComputing/CloudOps';
import DevOps from '../Pages/CloudComputing/DevOps';
import Docker from '../Pages/CloudComputing/Docker';
import HireSkilledEngineers from '../Pages/HireSkilledEngineers';
import ProductDevelopment from '../Pages/ProductDevelopment';
import ITArchitecture from '../Pages/ITArchitecture';
import Containers from '../Pages/CloudComputing/Containers';
import Ionic from '../Pages/AppDevelopment/Ionic';

// other pages
import ComingSoon from '../Pages/ComingSoon';
import StudentRegistration from '../Pages/StudentRegistration';

export function Router() {
    return (
        <ScrollIntoView>
        <Switch>
            <Route exact path={'/'} component={Home} />
            {/* single pages */}
            <Route path='/home' component={Home} /> 
            <Route path='/about-us' component={AboutUs}/>
            <Route path='/contact-us' component={Contactus}/>
            <Route path='/services' component={Services}/>
            <Route path='/blog' component={Blog}/>

            {/* technical tab pages */}
            <Route path='/full-stack-development' component={FullStackDevelopment}/>
            <Route path='/ruby' component={Ruby}/>
            <Route path='/angular' component={Angular}/>
            <Route path='/vue' component={Vue}/>
            <Route path='/wordpress' component={Wordpress}/>
            <Route path='/square-space' component={SquareSpace}/>
            <Route path='/flutter' component={Flutter}/>

            {/* software built tab pages */}
            <Route path='/product-development' component={ProductDevelopment}/>
            <Route path='/drupal' component={Drupal}/>
            <Route path='/cloudops' component={CloudOps}/>
            <Route path='/python' component={Python}/>

            {/* services tab pages */}
            <Route path='/react' component={ReactJS}/>
            <Route path='/android-ios' component={AndroidiOS}/>
            <Route path='/devops' component={DevOps}/>
            <Route path='/docker' component={Docker}/>
            <Route path='/ionic' component={Ionic}/>

            {/* expedient tab pages */}
            <Route path='/careers' component={Careers}/>
            <Route path='/containers' component={Containers}/>
            <Route path='/react-native' component={ReactNative}/>
            <Route path='/it-architecture' component={ITArchitecture}/>
            <Route path='/hire-skilled-engineers' component={HireSkilledEngineers}/>
            <Route path='/faq' component={FAQ}/>

            {/* other pages */}
            <Route path='/comingsoon' component={ComingSoon}/>
            <Route path='/student-registration' component={StudentRegistration}/>


        </Switch>
        </ScrollIntoView>
    );
}

export default Router;

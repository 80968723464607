import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ComingSoon from "../ComingSoon";

export default function Drupal() {
  return (
    <div>
      {/* Start Breadcrumb 
    ============================================= */}
      <div
        className="no-breadcrumb breadcrumb-area text-center shadow dark text-light bg-cover"
        // style={{ backgroundImage: "url(assets/img/2440x1578.png)" , height: 150}}
      >
        {/* <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h4>Drupal</h4>              
            </div>
          </div>
        </div> */}
      </div>
      {/* End Breadcrumb */}

      <div className="services-details-area default-padding pb-4">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-12 col-lg-8 col-md-8 col-sm-8 services-single-content">
                <h2>Drupal Development Services</h2>
                <p>
                  We create large websites and web applications by using Drupal.
                  Such systems are easy to scale and customize and help you get
                  a high position in Google.
                </p>

                <p>
                Suchita Consulting built our team around Drupal and we're hiring the skilled developers and techi
                  from all over the world. 
                </p>
                <p>Our main agenda is to create large websites by using 
                  Drupal the right way. Our great advantage is the fact that our
                  processes are focused on automation when building Drupal
                  systems. </p>
                  <p>Suchita Consulting having a single code bitbucket repository, automated build
                  process, and testing environment that reflects production as
                  closely as possible. We often commit changes and perform
                  automated testing.</p>
              </div>
              <div className="col-12 col-lg-4 col-md-4 col-sm-4 services-sidebar">
                <img src="assets/img/list/web-frame/18.png" alt="Thumb" />
              </div>
            </div>

            <div className="row default-padding pb-0">
            <div className="col-lg-4 services-sidebar">
                <img src="assets/img/list/cms/1.png" alt="Thumb" />
              </div>
              <div className="col-lg-8 services-single-content pl-5">
                <h2>Drupal development - case studies</h2>
                <p>
                Since 2020, Suchita Consulting built no. of websites for companies representing a broad range of information technology companies, manufacturing, finance, charity, ecommerce web applications, publishing and ecommerce. Drupal is a great choice for all the IT companies either MNC, midcap and small cap companies. 
                </p>
                <p>
                So we're ready to cooperate with any type of business. We’re always excited to take up new challenges. Look at the projects we've already completed.
                </p>

              </div>
              
            </div>

          </div>
        </div>
      </div>

      {/* Star About Area
    ============================================= */}
      <div className="about-area bg-dark overflow-hidden text-light relative">
        {/* Fixed Shape */}
        <div
          className="fixed-shape"
          style={{ backgroundImage: "url(assets/img/shape/8.png)" }}
        />
        {/* End Fixed Shape */}
        <div className="container">
          <div className="about-items">
            <div className="row">
              <div
                className="col-lg-6 thumb order-lg-last"
                style={{ backgroundImage: "url(assets/img/list/cms/2.png)" }}
              >
                <div className="successr-ate">
                  <div className="icon">
                    <i className="flaticon-target" />
                  </div>
                  <div className="content">
                    <h2>98%</h2>
                    <span>Success Rate</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 info">
                <h4>Who we are</h4>
                <h2>
                  We Combine Technology <br /> with Business Ideas
                </h2>
                <p>
                Suchita Consulting is a leading IT solutions company that excels in delivering Web development and android applications.

                </p>
                <p>With an experience of 2+ years, we aim to deliver excellent solutions to our genuine clients. We understand that future world is rapidly shifting towards data-driven and AI, Machine learning technologies.
</p>
                <ul>
                  <li>
                    <h5>Innovate with perfection
</h5>
                    <p>
                    Yes, You have landed on the right page. We are here to help our client in every possible way.
                    </p>
                  </li>
                  <li>
                    <h5>Information Security Management
</h5>
                    <p>
                    We prefer quality over quantity and that is what makes Suchita Consulting one of the bests in the market. We are here to help our client in every possible way.
                    </p>
                  </li>
                </ul>
                <Link to="/contact-us" className="btn btn-light effect btn-md">
                  Discover More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area */}
    </div>
  );
}

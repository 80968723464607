import { data } from "jquery";
import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";

export default function GenericBlog(props: any) {

  const img1 = require("../../assets/images/1.png");
  const img2 = require("../../assets/images/2.png");
  const img3 = require("../../assets/images/3.png");
  
  const blogList = [
    {
      imagesource: img1,
      date: "01-Mar-2022",
      by: "javinpaul",
      source: "Medium",
      title: "Modern JavaScript Explained For Dinosaurs",
      description: "This is probably the best explanation I’ve ever seen about modern frontend development workflow, and if you are new to JavaScript or web development, you must read this article. You will learn so much about JavaScript by just reading this one article. If you have looked at JavaScript 10 years ago and want to get up-to-speed quickly, definitely read this article And, if you want to learn more about JavaScript The Complete JavaScript Course by Jonas Schemdtmann on Udemy is the best course for web developers.",
    },   
    {
      imagesource: img2,
      date: "02-Mar-2022",
      by: "javinpaul",
      source: "Medium",
      title: "The Web Developer RoadMap",
      description: "This is not just an article of the sort but a fantastic resource on what it takes to become a Web developer. It lists all the technologies and tools a Web developer should be familiar with. I really liked those mind-maps which appear now in many other places on the web. It effectively complements The Web Developer Bootcamp course, which teaches you most of the technologies mentioned in this roadmap.",
    }, 
    {
      imagesource: img3,
      date: "04-Mar-2022",
      by: "javinpaul",
      source: "Medium",
      title: "Web Architecture 101",
      description: "The basic architecture concepts which I believe every web developer should learn and understand. If you have just started with web development, you may find it complicated, but it’s worth reading. I won’t say much, as the below diagram says it all. I always try to understand the flow before doing any change or while debugging issues. Once you know workflow like where is the input coming from, where is the logic and data, and how the response is returned makes a lot of difference in your day-to-day work. And, If you’re looking for a complete course on web application and software architecture, I recommend checking out Web Application and Software Architecture 101. This is a useful course for anyone looking to strengthen their overall knowledge of software architecture.",
    }, 
  ];

  return (
    <div>
      {/* Start Blog */}
      <div className="blog-area default-padding bottom-less">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="site-heading text-center">
                <h4>{props.heading}</h4>
                <h2>{props.des}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="blog-items">
            <div className="row">
              {/* Single Item */}

              {blogList.map((data) => {
                return (
                  <div className="single-item col-lg-4 col-md-6">
                    <div className="item">
                      <div className="thumb">
                        <img src={data.imagesource} alt="Thumb" />
                        <div className="date">{data.date}</div>
                      </div>
                      <div className="info">
                        <div className="meta">
                          <ul>
                            <li>
                              {/* <img src="assets/img/100x100.png" alt="Author" /> */}
                              <span>By </span>
                              <a>{data.by}</a>
                            </li>
                            <li>
                              <span>In </span>
                              <a>{data.source}</a>
                            </li>
                          </ul>
                        </div>
                        <h4>
                          <a title={data.title}>
                            {data.title}
                          </a>
                        </h4>
                        <p>
                          {data.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Area */}
    </div>
  );
}

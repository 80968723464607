import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ClientList from "../GenericComponents/ClientList";
import ServicesList from "../GenericComponents/ServicesList";
import ComingSoon from "./ComingSoon";

export default function Services() {
  return (
    <div>
      {/* Start Breadcrumb 
    ============================================= */}
      <div
        className="no-breadcrumb breadcrumb-area text-center shadow dark text-light bg-cover"
        // style={{ backgroundImage: "url(assets/img/services/3.png)" }}
      >
        {/* <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Our Services</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="#">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Services</li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
      {/* End Breadcrumb */}


      <ServicesList sectionheading="OUR SERVICES" sectiondes="What We Bring To You"/>

   
      <h2 className="text-center">Web Application Responsive Design & Development</h2>

   <div className="d-flex justify-content-center">
    <img src="assets/img/services/4.png" className="services-responsive-imgbanner"/>
    </div>

      {/* Star Services Area
    ============================================= */}
      <div className="services-area bg-gray default-padding bottom-less">
        {/* Fixed Shape */}
        <div
          className="fixed-shape"
          style={{ backgroundImage: "url(assets/img/shape/12.png)" }}
        />
        {/* Fixed Shape */}
        <div className="container">
          <div className="services-heading">
            <div className="row">
              <div className="col-lg-7 left-info">
                <h4>Know about us?</h4>
                <h2>
                Create a Diverse, Equitable &   <br /> Inclusive Workplace
                </h2>
              </div>
              <div className="col-lg-5 right-info">
                <ul className="achivement">
                  <li>
                    <div className="fun-fact">
                      <div className="counter">
                        <div
                          className="timer"
                          data-to={80}
                          data-speed={1000}
                        >
                          80
                        </div>
                        <div className="operator">%+</div>
                      </div>
                      <span className="medium">Qualified applicants</span>
                    </div>
                  </li>
                  <li>
                    <div className="fun-fact">
                      <div className="counter">
                        <div className="timer" 
                        data-to={75} 
                        data-speed={1000}
                        >
                         75
                        </div>
                        <div className="operator">%+</div>
                      </div>
                      <span className="medium">Great work place </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Start Services Items */}
          <div className="services-content text-center">
            <div className="row">
              {/* Single Item */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <i className="flaticon-funds" />
                  <h4>
                    <a href="#">Good work place culture</a>
                  </h4>
                  <p>
                    We're trying to build a great global company culture.
                  </p>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <i className="flaticon-funds" />
                  <h4>
                    <a href="#">Employee Engagement</a>
                  </h4>
                  <p>
                    Keep your top talent engaged and Increase engagement and retention.
                  </p>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <i className="flaticon-analysis" />
                  <h4>
                    <a href="#">Recruitment Process</a>
                  </h4>
                  <p>
                  Skilled engineers can work wherever they want and we hire them. 
                  </p>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <i className="flaticon-analysis" />
                  <h4>
                    <a href="#">Diversity</a>
                  </h4>
                  <p>
                  We build an excellent workplace that will unlock better team communication.
                  </p>
                </div>
              </div>
              {/* End Single Item */}
            </div>
          </div>
          {/* End Services Items */}
        </div>
      </div>
      {/* End Services Area */}
      {/* Star Partner Area
    ============================================= */}
      <div className="partner-area bg-gray shadow-less overflow-hidden text-light">
        <div className="container">
          <div className="item-box">
            <div className="row align-center">
              <div className="col-lg-6 info">
              <h2>
                  We Combine Technology <br /> with Business Thoughts
                </h2>
                <p>
                  Suchita Consulting is a leading IT solutions company that excels
                  in delivering Web development and android applications.
                </p><br/>
                <p>
                  With an experience of 2+ years, we aim to deliver excellent
                  solutions to our genuine clients. We understand that future
                  world is rapidly shifting towards data-driven and AI, Machine
                  learning technologies.
                </p>               
              </div>
              <div className="col-lg-6 clients">
                <ClientList/>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Partner Area */}


    </div>
  );
}

import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ComingSoon from "../ComingSoon";
import WebFrameList from "./Generic";

export default function Python() {
  return (
    <ComingSoon/>
    // <div>
    //   {/* Start Breadcrumb 
    // ============================================= */}
    //   <div
    //     className="breadcrumb-area text-center shadow dark text-light bg-cover"
    //     style={{ backgroundImage: "url(assets/img/2440x1578.png)" }}
    //   >
    //     <div className="container">
    //       <div className="row">
    //         <div className="col-lg-8 offset-lg-2">
    //           <h1>Python</h1>
    //           <ul className="breadcrumb">
    //             <li>
    //               <a href="#">
    //                 <i className="fas fa-home" /> Python
    //               </a>
    //             </li>
    //           </ul>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* End Breadcrumb */}
    //   {/* Star Services Details Area
    // ============================================= */}
    //   <div className="services-details-area default-padding">
    //     <div className="container">
    //       <div className="services-details-items">
    //         <div className="row">
    //           <div className="col-lg-4 services-sidebar">
    //                 <WebFrameList/>
    //           </div>
    //           <div className="col-lg-8 services-single-content">
    //             <img src="assets/img/1500x700.png" alt="Thumb" />
    //             <h2>Creative &amp; Digital ideas</h2>
    //             <p>
    //               We denounce with righteous indige nation and dislike men who
    //               are so beguiled and demo realized by the charms of pleasure of
    //               the moment, so blinded by desire, that they cannot foresee the
    //               pain and trouble that are bound to ensue cannot foresee. These
    //               cases are perfectly simple and easy to distinguish. In a free
    //               hour, when our power of choice is untrammelled data structures
    //               manages data in technology.
    //             </p>
    //             <div className="features">
    //               <div className="row">
    //                 <div className="col-lg-6 col-md-6">
    //                   <div className="content">
    //                     <h4>Finance Restructuring</h4>
    //                     <p>
    //                       These cases are perfectly simple and easy to
    //                       distinguish. In a free hour, when our power of choice
    //                       is untrammelled.
    //                     </p>
    //                   </div>
    //                 </div>
    //                 <div className="col-lg-6 col-md-6">
    //                   <div className="content">
    //                     <h4>International Markets</h4>
    //                     <p>
    //                       These cases are perfectly simple and easy to
    //                       distinguish. In a free hour, when our power of choice
    //                       is untrammelled.
    //                     </p>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             <p>
    //               Nam libero tempore, cum soluta nobis est elig endi optio
    //               cumque nihil impedit quo minus id quod maxime placeat facere
    //               possimus, omnis voluptas assumenda est, omnis dolor repelle
    //               ndus. Temporibus autem quibusdam et aut officiis debitis aut
    //               rerum necessitatibus saepe eveniet ut et voluptates
    //               repudiandae sint et molestiae non recusandae. Itaque earum
    //               rerum hic tenetur a sapiente delectus, ut aut reiciendis
    //               voluptatibus maiores alias. consequatur aut perferendis
    //               doloribus asperiores repellat. The wise man therefore always
    //               holds in these matters to this principle of selection: he
    //               rejects pleasures to secure other greater pleasures, or else
    //               he endures pains to avoid worse pains. pleasures have to be
    //               repudiated and annoyances accepted.
    //             </p>
    //             <h2>Corporate identity</h2>
    //             <ul>
    //               <li>
    //                 How will digital activities impact traditional
    //                 manufacturing.
    //               </li>
    //               <li>
    //                 All these digital elements and projects aim to enhance .
    //               </li>
    //               <li>
    //                 I monitor my staff with software that takes screenshots.
    //               </li>
    //               <li>
    //                 Laoreet dolore magna niacin sodium glutimate aliquam
    //                 hendrerit.
    //               </li>
    //               <li>
    //                 Minim veniam quis niacin sodium glutimate nostrud exerci
    //                 dolor.
    //               </li>
    //             </ul>
    //           </div>
            
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* End Services Details Area */}
    // </div>
  );
}

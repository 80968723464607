import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";

export default function Testimonials() {
  return (
    <div>
      {/* Star testimonials Area
    ============================================= */}
      <div className="testimonials-area bg-gray default-padding">
        {/* Fixed Shape */}
        <div
          className="fixed-shape"
          style={{ backgroundImage: "url(assets/img/shape/10.png)" }}
        />
        {/* End Fixed Shape */}
        <div className="container">
          <div className="testimonial-items">
            <div className="row align-center">
              <div className="col-lg-7 testimonials-content">
                <div className="testimonials-carousel owl-carousel owl-theme">
                  {/* Single Item */}
                  <div className="item">
                    <div className="info">
                      <p>
                      "Great Place to Work allows us to take something fluid, like culture, and make it data-based and measurable. "
                      </p>
                      <div className="provider">
                        {/* <div className="thumb">
                          <img src="assets/img/100x100.png" alt="Author" />
                        </div> */}
                        <div className="content">
                          <h4>Venkat. D</h4>
                          <span> Managing Director</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Single Item */}
                  {/* Single Item */}
                  <div className="item">
                    <div className="info">
                      <p>
                      "It is easier for me to have conversations about culture with employees when I can point to the data."
                      </p>
                      <div className="provider">
                        {/* <div className="thumb">
                          <img src="assets/img/100x100.png" alt="Author" />
                        </div> */}
                        <div className="content">
                          <h4>Kiran Varma</h4>
                          <span> Full-Stack Developer</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Single Item */}
                </div>
              </div>
              <div className="col-lg-5 info">
                <h4>Testimonials</h4>
                <h2>Check what our satisfied clients said</h2>
                <p>
                  Our clients are very happy to working with us and here adding few comments by multiple various valuable clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End testimonials Area */}
    </div>
  );
}

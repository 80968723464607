import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ComingSoon from "./ComingSoon";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ClientList from "../GenericComponents/ClientList";
import Testimonials from "../GenericComponents/Testimonials";

export default function HireSkilledEngineers() {
  const dataList = [
    {
      title: "The ability to analyse hiring process",
      description:
        "",
    },
    {
      title: "Improve quality of candidates",
      description:
        "",
    },
    {
      title: "Create employer brand awareness",
      description:
        " ",
    },
    {
      title: "Make the recruitment process scalable",
      description: "",
    },
  ];

  return (
    <div>
      {/* Start Breadcrumb */}
      <div
        className="no-breadcrumb breadcrumb-area text-center shadow dark text-light bg-cover"
        // style={{ backgroundImage: "url(assets/img/2440x1578.png)" }}
      >
        <div className="container">
          {/* <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Product Development</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="#">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Product Dev.</li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
      {/* End Breadcrumb */}

      {/* Start Expertise Area */}
      <div className="expertise-area default-padding pb-0 pt-5">
        <h2 className="text-center mb-5">We Hire Skilled Engineers!</h2>

        <div className="container">
          {/* Expertise Content */}
          <div
            className="expertise-content text-light"
            style={{ backgroundImage: "url(assets/img/list/other/3.png)" }}
          >
            <div className="row">
              {dataList.map((data) => {
                return (
                  <div className="col-lg-3 single-item">
                    <div className="item">
                      <div className="content">
                        <h4>{data.title}</h4>
                        <p>{data.description}</p>
                      </div>
                      <Link to="/services" className="btn btn-sm">
                        Know More
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* Fixed Shape */}
            <div
              className="fixed-shape"
              style={{ backgroundImage: "url(assets/img/shape/13.png)" }}
            />
            {/* Fixed Shape */}
          </div>
          {/* Expertise Content */}
        </div>
      </div>
      {/* End Expertise Area */}

      {/* Start Terms Policy */}
      <div className="terms-policy-area default-padding">
        <i className="fas fa-user-lock" />
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="test-1">
                <section>
                  <h3>What is Recruitment and Hiring process?</h3>
                  <p>
                    Recruitment is a core function of the Human Resource (HR)
                    department and it involves everything from identifying,
                    attracting, screening, shortlisting, interviewing,
                    selecting, hiring, and onboarding employees.
                  </p>
                  <p>
                    Most of the organizations outsource their recruiting needs,
                    while some companies rely exclusively on Indeed, Naukri,
                    Other job portals and social media channels to recruit
                    skilled persons for new positions.
                  </p>
                  <p>
                    Suchita Consulting having recruitment software to make our
                    recruitment process more effective, flexible and efficient.
                  </p>
                </section>

                <section>
                  <h3 className="mt-5">Our Recruitment Process in few steps</h3>
                  <ul>
                    <li>
                      <span>01</span>
                      <p>Identifying the Hiring Needs.</p>
                    </li>
                    <li>
                      <span>02</span>
                      <p>Preparing the Job Description</p>
                    </li>
                    <li>
                      <span>03</span>
                      <p>Talent Search.</p>
                    </li>
                    <li>
                      <span>04</span>
                      <p>Screening and Shortlisting.</p>
                    </li>
                    <li>
                      <span>05</span>
                      <p>Interviewing</p>
                    </li>
                    <li>
                      <span>06</span>
                      <p>Evaluation and Offer of Employment</p>
                    </li>
                    <li>
                      <span>07</span>
                      <p>Introduction and Induction of the New Employee.</p>
                    </li>
                  </ul>
                </section>

                <section>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <h3 className="mt-5">
                      What goes the recruitment process more effective?
                    </h3>
                    <p>
                      The recruitment process is an organization-specific
                      sourcing model that goal to find the right fit for the
                      right job at the right work place and right time.
                    </p>
                    <p>
                      <b>
                        It is a step-by-step approach to bringing in very
                        talented skilled people who can help the Suchita Consulting 
                        company grow.{" "}
                      </b>
                    </p>
                    <p>
                      The recruitment process has important 5 key phases but it
                      may vary from company to company depending on their
                      business, organizational culture, productivity, employee
                      size of the company, nature of operations and existing
                      recruitment work structure and candidate selection
                      process.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <img src="assets/img/list/other/4.png" className="mb-5" />
                  </div>
                </section>

                <section>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <img src="assets/img/list/other/5.png" className="mb-5" />
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h3 className="mt-5">
                      Hiring Strategy!
                    </h3>
                   
                    <p>
                      The recruitment process has important 5 key phases but it
                      may vary from company to company depending on their
                      business, organizational culture, productivity, employee
                      size of the company, nature of operations and existing
                      recruitment work structure and candidate selection
                      process.
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Terms Policy */}

      <Testimonials />

      {/* Star Partner Area
    ============================================= */}
      <div className="partner-area bg-gray shadow-less overflow-hidden text-light">
        <div className="container">
          <div className="item-box">
            <div className="row align-center">
              <div className="col-lg-6 info">
                <h2>
                  We're Trusted by <span>42+</span> <br /> Professional
                  Customer
                </h2>
                <p>
                  Seeing rather her you not esteem men settle genius excuse.
                  Deal say over you age from. Comparison new ham melancholy son
                  themselves.
                </p>
              </div>
              <div className="col-lg-6 clients">
                <ClientList />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Partner Area */}
    </div>
  );
}

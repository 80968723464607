import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2'

const StudentRegistration = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_pyfyy4g', 'template_fz2x8qa', form.current, 'user_FIgygPWj3vQuFBn77mIao')
            .then((result) => {
                console.log(result.text);
                Swal.fire({
                    title: 'Thank you for registering!',
                    text: 'We will get back soon.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                  })  
            }, (error) => {
                console.log(error.text);
                Swal.fire({
                    title: 'Error!',
                    text: error?.response?.data?.error?.message ? error?.response?.data?.error?.message  : 'Something went wrong!',
                    icon: 'error',
                    confirmButtonText: 'OK'
                  })  
            });
        e.target.reset()
    };

    return (
        <>
            <div className="inner-page-banner-area" style={{ backgroundImage: 'url(img/banner/5.jpg)' }}>
                <div className="container">
                    <div className="pagination-area">
                        <h1>Registration</h1>
                        <ul>
                            <li><a href="#">Home</a>/</li>
                            <li>registration</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="registration-page-area">
                <div className="container">
                    <h2 className="sidebar-title">Registration</h2>
                    <div className="registration-details-area inner-page-padding">
                        <form id="checkout-form" ref={form} onSubmit={sendEmail}>
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                                    <div className="form-group">
                                        <label>Subject</label>
                                        <input type="text" className="form-control" placeholder="Enter subject" name="subject" />
                                    </div>
                                </div>     
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input type="text" className="form-control" placeholder="Enter your name" name="name" />
                                    </div>
                                </div>                          
                            </div>
                            {/* <div className="row">
                                
                            </div> */}
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                                    <div className="form-group">
                                    <label>Email</label>
                                        <input type="email" className="form-control" placeholder="Enter your email address" name="email" />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                                    <div className="form-group">
                                    <label>Mobile number</label>
                                        <input type="number" className="form-control" placeholder="Enter your mobile number" name="mobile" />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                              
                            </div> */}
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div className="pLace-order mt-30">
                                        <button className="view-all-accent-btn" type="submit" value="Submit">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    );
};

export default StudentRegistration;
import styled, {css} from 'styled-components';
import {sideBar, arrowDimentions} from '../variables';

interface Selected {
    isOpen?: boolean
}

export const flexCenter = css`   
    align-items: center;
    justify-content: center;
`;

export const alignedCenter = css`   
    align-items: center;
`;

export const displayFlex = css`
    display: flex;   
`;

export const cursorPointer = css`
    cursor: pointer;
`;

export const Padding0y50x = css`
    padding: 0px 50px;
`;

export const fontThin = css`
    font-weight: 200;
`;

export const fontRegular = css`
    font-weight: 400;
`;

export const fontMedium = css`
    font-weight: 500;
`;

export const fontBold = css`
    font-weight: 700;
`;

export const FontLight = styled.b`
    font-weight: 400;
    color: ${(props) => props.theme.primary.fontLight};
`;

export const FlexCenter = styled.div`    
    ${flexCenter};
    ${displayFlex};
`;

export const DisplayCenter = styled.div`    
    ${flexCenter};
    ${displayFlex};
    flex: 1;
`;

export const rowSpaceBetween = styled.div`    
    ${displayFlex};
    flex-flow: column;
`;

export const AppContainer = styled.div`
    width: 100%;
    ${displayFlex};
    -webkit-flex-flow: column;
    flex-flow: column;
    height: 100%;

    .piechart-wrapper {
        border-bottom: solid;
    }
`;

export const MainContainer = styled.div`
    overflow: auto;
`;

export const AdView = styled.div`
    width: ${sideBar};
    background-color: #ccc;
    ${displayFlex};
    -webkit-flex-flow: column;
    flex-flow: column;
    background-image: linear-gradient(to right,#7073c7,#3f51b5);
`;

export const ArrowView = styled.div<Selected>`
    position: fixed;
    right: ${(props) => props.isOpen ? '200px' : '0'};
    bottom: 0;
    background-color: ${(props) => props.theme.primary.whiteColor};
    border: solid 2px ${(props) => props.theme.primary.color};
    border-radius: 100px;
    width: ${arrowDimentions};
    height: ${arrowDimentions};
    ${displayFlex};
    ${flexCenter}; 
    ${cursorPointer};   
    z-index: 9999;
`;

export const HomeImageView = styled.div`    
    ${displayFlex};
    ${flexCenter}; 

    img {
        width: 100%;
        height: 600px;
        object-fit: cover;
    }
`;

export const TitleHeader = styled.div`  
    margin-top: 30px;
    margin-bottom: 30px;  

    @media (max-width: 767px) {
        width: 100%!important;
    }

    h5 {
        ${fontMedium};
        text-transform: capitalize;
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 5px;
        color: ${(props) => props.theme.primary.secondaryColor};
    }

    .title {
        font-size: 34px;
        line-height: 44px;
        margin-bottom: 0;
        color: ${(props) => props.theme.primary.primaryColor};        
        font-style: italic;

        span {  
            ${fontBold};  
            font-style: normal;       
            ${fontBold};
            font-family: 'Playfair Display', serif;
            letter-spacing: 1.2px;
            color: ${(props) => props.theme.primary.secondaryColor};
        }
    }
`;

export const Sticky = styled.div`
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 99;

    @media (max-width: 767px) {
        position: relative;
   }
`;

export const SpecialFont = styled.b`
    color: ${(props): string => props.theme.primary.secondaryColor};
    font-size: 25px;
`;

import React from "react";
import ourimpactIMG from "../../assets/images/ourimpactIMG.jpg";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import FeaturedCourses from "../../GenericComponents/FeaturedCourses";
import ComingSoon from "../ComingSoon";

export default function AndroidiOS() {
  return (
    <ComingSoon/>
    // <div>
    //   {/* Start Breadcrumb 
    // ============================================= */}
    //   <div
    //     className="no-breadcrumb breadcrumb-area text-center shadow dark text-light bg-cover"
    //     style={{ backgroundImage: "url(assets/img/banner/no-breadcrumb-banner.jpg)" }}
    //   >
       
    //   </div>
    //   {/* End Breadcrumb */}


    //   {/* Start Terms Policy 
    // ============================================= */}
    //   <div className="terms-policy-area mt-5">
    //     <i className="fas fa-user-lock" />
    //     <div className="container">
    //       <div className="row">
    //         <div className="col-lg-10 offset-lg-1">
    //           <h3>Religious beliefs</h3>
    //           <p>
    //             Scelerisque in dictum non consectetur a. Porta nibh venenatis
    //             cras sed felis. Tincidunt nunc pulvinar sapien et ligula
    //             ullamcorper malesuada proin libero. Est velit egestas dui id
    //             ornare arcu. Venenatis a condimentum vitae sapien pellentesque
    //             habitant. Lectus sit amet est placerat. Vitae proin sagittis
    //             nisl rhoncus mattis rhoncus urna neque. General Disclaimer
    //           </p>
    //           <ul>
    //             <li>
    //               <span>01</span>
    //               <p>
    //                 Condimentum vitae sapien pellentesque habitant. Lectus sit
    //                 amet est placerat. Vitae proin sagittis nisl rhoncus mattis
    //                 rhoncus urna neque. General Disclaimer
    //               </p>
    //             </li>
    //             <li>
    //               <span>02</span>
    //               <p>
    //                 Morbi non arcu risus quis varius. Odio morbi quis commodo
    //                 odio. Consectetur a erat nam at lectus urna duis convallis
    //                 convallis.
    //               </p>
    //             </li>
    //           </ul>
    //           <p>
    //             Pulvinar proin gravida hendrerit lectus a. Placerat vestibulum
    //             lectus mauris ultrices eros in. Faucibus interdum posuere lorem
    //             ipsum. Malesuada proin libero nunc consequat interdum varius.
    //             Ullamcorper morbi tincidunt ornare massa. Magna etiam tempor
    //             orci eu lobortis elementum. Quam viverra orci sagittis eu
    //             volutpat odio. Hendrerit gravida rutrum quisque non tellus.
    //             Euismod lacinia at quis risus. Cursus in hac habitasse platea.
    //             Id porta nibh venenatis cras sed felis eget. Turpis cursus in
    //             hac habitasse. Velit scelerisque in dictum non consectetur a.
    //             Porta nibh venenatis cras sed felis. Tincidunt nunc pulvinar
    //             sapien et ligula ullamcorper malesuada proin libero. Est velit
    //             egestas dui id ornare arcu. Venenatis a condimentum vitae sapien
    //             pellentesque habitant. Lectus sit amet est placerat. Vitae proin
    //             sagittis nisl rhoncus mattis rhoncus urna neque. General
    //             Disclaimer
    //           </p>
    //           <p>
    //             Nunc aliquet bibendum enim facilisis gravida neque convallis a
    //             cras. Morbi non arcu risus quis varius. Odio morbi quis commodo
    //             odio. Consectetur a erat nam at lectus urna duis convallis
    //             convallis. Nibh venenatis cras sed felis eget velit aliquet
    //             sagittis id. Egestas congue quisque egestas diam in arcu. Arcu
    //             odio ut sem nulla pharetra diam sit amet. Cras pulvinar mattis
    //             nunc sed blandit libero volutpat. Dui sapien eget mi proin.
    //             Donec massa sapien faucibus et molestie. Dolor sit amet
    //             consectetur adipiscing elit ut aliquam purus sit. Ultricies mi
    //             eget mauris pharetra. Augue lacus viverra vitae congue eu
    //             consequat ac.
    //           </p>
    //           <h3>Required by the law</h3>
    //           <p>
    //             Pulvinar proin gravida hendrerit lectus a. Placerat vestibulum
    //             lectus mauris ultrices eros in. Faucibus interdum posuere lorem
    //             ipsum. Malesuada proin libero nunc consequat interdum varius.
    //             Ullamcorper morbi tincidunt ornare massa. Magna etiam tempor
    //             orci eu lobortis elementum. Quam viverra orci sagittis eu
    //             volutpat odio. Velit scelerisque in dictum non consectetur a.
    //             Porta nibh venenatis cras sed felis. Tincidunt nunc pulvinar
    //             sapien et ligula ullamcorper malesuada proin libero. Est velit
    //             egestas dui id ornare arcu. Venenatis a condimentum vitae sapien
    //             pellentesque habitant. Lectus sit amet est placerat. Vitae proin
    //             sagittis nisl rhoncus mattis rhoncus urna neque. General
    //             Disclaimer
    //           </p>
    //           <h3>Transparency</h3>
    //           <p>
    //             Nunc aliquet bibendum enim facilisis gravida neque convallis a
    //             cras. Morbi non arcu risus quis varius. Odio morbi quis commodo
    //             odio. Consectetur a erat nam at lectus urna duis convallis
    //             convallis. Nibh venenatis cras sed felis eget velit aliquet
    //             sagittis id. Egestas congue quisque egestas diam in arcu. Arcu
    //             odio ut sem nulla pharetra diam sit amet. Cras pulvinar mattis
    //             nunc sed blandit libero volutpat. Dui sapien eget mi proin.
    //             Donec massa sapien faucibus et molestie. Dolor sit amet
    //             consectetur adipiscing elit ut aliquam purus sit. Ultricies mi
    //             eget mauris pharetra. Augue lacus viverra vitae congue eu
    //             consequat ac.
    //           </p>
    //           <p>
    //             Pulvinar proin gravida hendrerit lectus a. Placerat vestibulum
    //             lectus mauris ultrices eros in. Faucibus interdum posuere lorem
    //             ipsum. Malesuada proin libero nunc consequat interdum varius.
    //             Ullamcorper morbi tincidunt ornare massa. Magna etiam tempor
    //             orci eu lobortis elementum. Quam viverra orci sagittis eu
    //             volutpat odio. Velit scelerisque in dictum non consectetur a.
    //             Porta nibh venenatis cras sed felis. Tincidunt nunc pulvinar
    //             sapien et ligula ullamcorper malesuada proin libero. Est velit
    //             egestas dui id ornare arcu. Venenatis a condimentum vitae sapien
    //             pellentesque habitant. Lectus sit amet est placerat. Vitae proin
    //             sagittis nisl rhoncus mattis rhoncus urna neque. General
    //             Disclaimer
    //           </p>
    //           <h3>Social Media</h3>
    //           <p>
    //             Pulvinar proin gravida hendrerit lectus a. Placerat vestibulum
    //             lectus mauris ultrices eros in. Faucibus interdum posuere lorem
    //             ipsum. Malesuada proin libero nunc consequat interdum varius.
    //             Ullamcorper morbi tincidunt ornare massa. Magna etiam tempor
    //             orci eu lobortis elementum. Quam viverra orci sagittis eu
    //             volutpat odio. Hendrerit gravida rutrum quisque non tellus.
    //             Euismod lacinia at quis risus. Cursus in hac habitasse platea.
    //             Id porta nibh venenatis cras sed felis eget. Turpis cursus in
    //             hac habitasse. Velit scelerisque in dictum non consectetur a.
    //             Porta nibh venenatis cras sed felis. Tincidunt nunc pulvinar
    //             sapien et ligula ullamcorper malesuada proin libero. Est velit
    //             egestas dui id ornare arcu. Venenatis a condimentum vitae sapien
    //             pellentesque habitant. Lectus sit amet est placerat. Vitae proin
    //             sagittis nisl rhoncus mattis rhoncus urna neque. General
    //             Disclaimer
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* End Terms Policy */}

    //   {/* Star Work Process Area
    // ============================================= */}
    //   <div className="work-process-area features-area default-padding">
    //     <div className="container">
    //       <div className="row">
    //         <div className="col-lg-8 offset-lg-2">
    //           <div className="site-heading text-center">
    //             <h4>See How It Works!</h4>
    //             <h2>Easy steps for business growth</h2>
    //             <div className="devider" />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="container">
    //       <div className="work-process-items features-content">
    //         <div className="row">
    //           {/* Single Item */}
    //           <div className="single-item col-lg-4 col-md-6">
    //             <div className="item">
    //               <i className="flaticon-speech-bubble" />
    //               <div className="top">
    //                 <span>01</span>
    //                 <h4>Discussion</h4>
    //               </div>
    //               <p>
    //                 Prevailed mr tolerably discourse assurance estimable
    //                 applauded to so. Him everything melancholy uncommonly but
    //                 solicitude.
    //               </p>
    //               <a href="#">
    //                 <i className="arrow_right" />
    //               </a>
    //             </div>
    //           </div>
    //           {/* Single Item */}
    //           {/* Single Item */}
    //           <div className="single-item col-lg-4 col-md-6">
    //             <div className="item">
    //               <i className="flaticon-label" />
    //               <div className="top">
    //                 <span>02</span>
    //                 <h4>Analysis</h4>
    //               </div>
    //               <p>
    //                 Prevailed mr tolerably discourse assurance estimable
    //                 applauded to so. Him everything melancholy uncommonly but
    //                 solicitude.
    //               </p>
    //               <a href="#">
    //                 <i className="arrow_right" />
    //               </a>
    //             </div>
    //           </div>
    //           {/* Single Item */}
    //           {/* Single Item */}
    //           <div className="single-item col-lg-4 col-md-6">
    //             <div className="item">
    //               <i className="flaticon-file" />
    //               <div className="top">
    //                 <span>03</span>
    //                 <h4>Implementation</h4>
    //               </div>
    //               <p>
    //                 Prevailed mr tolerably discourse assurance estimable
    //                 applauded to so. Him everything melancholy uncommonly but
    //                 solicitude.
    //               </p>
    //               <a href="#">
    //                 <i className="arrow_right" />
    //               </a>
    //             </div>
    //           </div>
    //           {/* Single Item */}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* End Work Process Area */}

    //   {/* Star Faq
    // ============================================= */}
    //   <div className="faq-area text-light overflow-hidden">
    //     <div className="container">
    //       <div className="faq-items">
    //         <div className="icon">
    //           <i className="flaticon-conversation" />
    //         </div>
    //         {/* Fixed Thumb */}
    //         <div className="fixed-thumb">
    //           <img src="assets/img/illustration/1.png" alt="Thumb" />
    //         </div>
    //         {/* End Fixed Thumb */}
    //         <div className="row align-center">
    //           <div className="col-lg-10 offset-lg-2">
    //             <div className="faq-content">
    //               <h2 className="area-title">Answer &amp; Question</h2>
    //               <div className="accordion" id="accordionExample">
    //                 <div className="card">
    //                   <div className="card-header" id="headingOne">
    //                     <h4
    //                       className="mb-0"
    //                       data-toggle="collapse"
    //                       data-target="#collapseOne"
    //                       aria-expanded="true"
    //                       aria-controls="collapseOne"
    //                     >
    //                       Do I need a business plan?
    //                     </h4>
    //                   </div>
    //                   <div
    //                     id="collapseOne"
    //                     className="collapse show"
    //                     aria-labelledby="headingOne"
    //                     data-parent="#accordionExample"
    //                   >
    //                     <div className="card-body">
    //                       <p>
    //                         Continue building numerous of at relation in
    //                         margaret. Lasted engage roused mother an am at.
    //                         Other early while if by do to. Missed living excuse
    //                         as be. Cause heard fat above first time achivement.
    //                         Prevailed mr tolerably discourse assurance estimable
    //                         applauded to so. Him everything melancholy
    //                         uncommonly but solicitude.
    //                       </p>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="card">
    //                   <div className="card-header" id="headingTwo">
    //                     <h4
    //                       className="mb-0 collapsed"
    //                       data-toggle="collapse"
    //                       data-target="#collapseTwo"
    //                       aria-expanded="false"
    //                       aria-controls="collapseTwo"
    //                     >
    //                       How long should a business plan be?
    //                     </h4>
    //                   </div>
    //                   <div
    //                     id="collapseTwo"
    //                     className="collapse"
    //                     aria-labelledby="headingTwo"
    //                     data-parent="#accordionExample"
    //                   >
    //                     <div className="card-body">
    //                       <p>
    //                         Continue building numerous of at relation in
    //                         margaret. Lasted engage roused mother an am at.
    //                         Other early while if by do to. Missed living excuse
    //                         as be. Cause heard fat above first time achivement.
    //                         Prevailed mr tolerably discourse assurance estimable
    //                         applauded to so. Him everything melancholy
    //                         uncommonly but solicitude.
    //                       </p>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="card">
    //                   <div className="card-header" id="headingFour">
    //                     <h4
    //                       className="mb-0 collapsed"
    //                       data-toggle="collapse"
    //                       data-target="#collapseFour"
    //                       aria-expanded="false"
    //                       aria-controls="collapseFour"
    //                     >
    //                       Where do I start?
    //                     </h4>
    //                   </div>
    //                   <div
    //                     id="collapseFour"
    //                     className="collapse"
    //                     aria-labelledby="headingFour"
    //                     data-parent="#accordionExample"
    //                   >
    //                     <div className="card-body">
    //                       <p>
    //                         Continue building numerous of at relation in
    //                         margaret. Lasted engage roused mother an am at.
    //                         Other early while if by do to. Missed living excuse
    //                         as be. Cause heard fat above first time achivement.
    //                         Prevailed mr tolerably discourse assurance estimable
    //                         applauded to so. Him everything melancholy
    //                         uncommonly but solicitude.
    //                       </p>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* End Faq */}

    // </div>
  );
}

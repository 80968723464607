import React from "react";
import ourimpactIMG from "../../assets/images/ourimpactIMG.jpg";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import FeaturedCourses from "../../GenericComponents/FeaturedCourses";
import ComingSoon from "../ComingSoon";
import WebFrameList from "./Generic";

export default function Ruby() {
  return (
    <div>
      {/* Start Breadcrumb 
    ============================================= */}
      <div
        className="no-breadcrumb breadcrumb-area text-center shadow dark text-light bg-cover"
        // style={{ backgroundImage: "url(assets/img/list/web-frame/11.png)" }}
      >
        {/* <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="py-5"></h1>
              <ul className="breadcrumb py-4">
                <li>
                  <a href="#">
                    <i className="fas fa-home" /> Angular
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
      {/* End Breadcrumb */}
      {/* Star Services Details Area
    ============================================= */}
      <div className="services-details-area default-padding">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-lg-8 services-single-content">
                <h2>What is Ruby on Rails?</h2>
                <ul>
                  <li>
                  RoR is a framework for the creation of a wide variety of websites. It is used for creating server-side (or back-end) web applications, i.e. those that run on the server (the applications that run on the user’s browser are called “front-end” applications).



                  </li>
                  <li>
                  Another core feature that our company makes RoR awesome for developers of Ruby on Rails sample projects is its RubyGems feature. RubyGems is a package manager that enables the creation and sharing of Ruby software libraries, called “gems”.Our company has  many useful gems for all sorts of situations available on the dedicated RubyGems website, and are easy to download and use.



                  </li>
                 
                </ul>
                <img src="assets/img/list/web-frame/16.png" alt="Thumb" />

                {/* <h2>9 Industries where flagship companies use Ruby on Rails</h2> */}

                {/* <p className="text-primary font-weight-bold">
                Vue continues to grow in popularity and is rapidly being adopted by many developers, and Vue.js tools are popping up everywhere.
                </p> */}

                {/* <img src="assets/img/list/web-frame/17.png" alt="Thumb" /> */}
              </div>
              <div className="col-lg-4 services-sidebar">
                <WebFrameList />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Services Details Area */}
    </div>
  );
}

import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ComingSoon from "../ComingSoon";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function Ionic() {
  const collapseList = [
    {
      title: "General purpose documentation.",
      description: "The documentation covers CLI installation, platform launches, app development start, and other information.",
      collapseid: "collapseOne"
    },
    {
      title: "Official migration guide.",
      description: "The most popular version of Ionic remains for now the third version. As there are a lot of apps written on it, the Ionic team’s documentation has a concise migration guide on the Ionic version 4.",
      collapseid: "collapseTwo"
    },
    {
      title: "Stencil library.",
      description: "This is for building and downloading web components.",
      collapseid: "collapseThree"
    },
    {
      title: "Cordova plugins repository.",
      description: "Apache Cordova provides a set of APIs to access native functionality.",
      collapseid: "collapseFour"
    },
    {
      title: "Capacitor plugins repository",
      description: "Capacitor is another platform for Ionic, that provides APIs to connect with devices hardware. Its plugins can be downloaded from npm.",
      collapseid: "collapseFive"
    },
    {
      title: "Integrations page",
      description: "This provides a list of tools, modules, and native plugins that can be integrated into your application.",
      collapseid: "collapseSix"
    }
  ];

  const workprocessAreaList = [
    {
      sno: "01",
      title: "Cross-Platform",
      description:
        "Apps work in both iOS and Android and Best for small development teams with limited resources",
    },
    {
      sno: "02",
      title: "Developers",
      description: "Developers having a strong web development background",
    },
    {
      sno: "03",
      title: "Long-term support",
      description: "Not recommended as the framework can get shut down",
    },
  ];

  const options = {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Ionic Usage'
    },
    subtitle: {
      text: ''
    },
    accessibility: {
      announceNewData: {
        enabled: true
      }
    },
    xAxis: {
      type: 'category'
    },
    yAxis: {
      title: {
        text: 'no. of companies'
      }

    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.y:.1f}'
        }
      }
    },

    tooltip: {
      headerFormat: '',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b><br/>'
    },

    series: [
      {
        name: "Comapnies",
        colorByPoint: true,
        data: [
          {
            name: "Startup",
            y: 50,
            drilldown: "Startup"
          },
          {
            name: "Mid Cap Companies",
             y: 180,
            drilldown: "Mid Cap Companies"
          },
          {
            name: "MNC",
            y: 25,
            drilldown: "MNC"
          }
        ]
      }
    ],
    drilldown: {
      breadcrumbs: {
        position: {
          align: 'right'
        }
      },
      series: [
        {
          name: "Startup",
          id: "Startup",
          data: []
        },
        {
          name: "Mid Cap Companies",
          id: "Mid Cap Companies",
          data: []
        },
        {
          name: "MNC",
          id: "MNC",
          data: []
        },
      ]
    }
  };

  return (
    <div>
      {/* Start Breadcrumb
       */}
      <div
        className="no-breadcrumb breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{
          backgroundImage: "url(assets/img/banner/no-breadcrumb-banner.jpg)",
        }}
      ></div>
      {/* End Breadcrumb */}

      {/* Start Terms Policy
       */}
      <div className="terms-policy-area mt-5">
        <i className="fas fa-user-lock" />
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <h3>What is the use of Ionic?</h3>
              <p>
                
Ionic is an open source UI toolkit for building performant, high-quality mobile and desktop apps using web technologies like HTML, CSS, and JavaScript — with integrations for popular frameworks like Angular, React, and Vue
              </p>
              <p>We build applications by using Ionic based on client requirement.</p>

              <img src="assets/img/list/mobile-app/9.png" className="mb-5" />
              {/* <img src="assets/img/list/mobile-app/3.png" className="mb-5 overlap-img" /> */}

              <h3>Companies Migrated to Ionic</h3>
         

              <HighchartsReact highcharts={Highcharts} options={options} />

              <h3>The Pros of Ionic Development</h3>
              <p>Ionic framework allows you to build android applications without hiring native developers. Anyone acquainted with web technologies and Angular can use it, leveraging web skills to create fully functional applications. Forming just one codebase for all of your platforms, guarantees:</p>
              <ul>
                <li>
                  <span>01</span>
                  <p>
                  Reduced costs on development, hiring native devs, codebase maintenance
                  </p>
                </li>
                <li>
                  <span>02</span>
                  <p>
                  Faster time-to-market on both platforms
                  </p>
                </li>
                <li>
                  <span>03</span>
                  <p>
                  Ease of maintenance via built-in browser instruments and debugging tools

                  </p>
                </li>
                <li>
                  <span>04</span>
                  <p>
                  Availability of transforming your Ionic application into a desktop app or PWA

                  </p>
                </li>
              </ul>

              
            </div>
          </div>
        </div>
      </div>
      {/* End Terms Policy */}


      {/* Star Faq
       */}
      <div className="faq-area text-light overflow-hidden">
        <div className="container">
          <div className="faq-items">
            <div className="icon">
              <i className="flaticon-conversation" />
            </div>
            {/* Fixed Thumb */}
            <div className="fixed-thumb">
              <img src="assets/img/illustration/1.png" alt="Thumb" />
            </div>
            {/* End Fixed Thumb */}
            <div className="row align-center">
              <div className="col-lg-10 offset-lg-2">
                <div className="faq-content">
                  <h2 className="area-title">Ionic starter pack</h2>
                  <div className="accordion" id="accordionExample">
                    {collapseList.map((data) => {
                      return (
                        <div className="card">
                          <div className="card-header" id="headingOne">
                            <h4
                              className="mb-0"
                              data-toggle="collapse"
                              data-target={`#${data.collapseid}`}
                              aria-expanded="true"
                              aria-controls={data.collapseid}
                            >
                              {data.title}
                            </h4>
                          </div>
                          <div
                            id={data.collapseid}
                            className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample"
                          >
                            <div className="card-body">
                              <p>
                              {data.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Faq */}
    </div>
  );
}

export const theme = {
    primary: {
        primaryColor: '#14081f',
        secondaryColor: '#ff5722',
        whiteColor: '#FFFFFF',
        blackColor: '#000000',
        thinBorder: 'rgba(30, 23, 23, 0.1)',
        fontLight: 'rgba(255, 255, 255, 0.8)'
    },
    light: {
        secondaryColor: 'rgba(253, 34, 106, 0.49)',
        whiteColor: 'rgba(255, 255, 255, 0.42)'
    }
}
import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import axios from "axios";

//components
import { API_URL } from "../../constants";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FeaturedCourses from "../GenericComponents/FeaturedCourses";
import Clients from "../GenericComponents/Clients";
import ClientList from "../GenericComponents/ClientList";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ServicesList from "../GenericComponents/ServicesList";
import WorksGallery from "./WorksGallery";
import GenericBlog from "../GenericComponents/GenericBlog";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      campaigns: [],
      isLoading: false,
    };
  }

  render() {
    const silde_1 = require("../../assets/images/weoffer/python.png");

    const options = {
      chart: {
        type: "column",
      },
      title: {
        text: "Suchita Consulting Company Growth",
      },
      subtitle: {
        text: "Business Chart",
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        title: {
          text: "value",
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "{point.y:.1f}",
          },
        },
      },

      tooltip: {
        headerFormat: "",
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b><br/>',
      },

      series: [
        {
          name: "Browsers",
          colorByPoint: true,
          data: [
            {
              name: "Employees",
              y: 1500,
              drilldown: "Employees",
            },
            {
              name: "Selected Candidates",
              y: 1800,
              drilldown: "Selected Candidates",
            },
            {
              name: "Interviewed Candidates",
              y: 4500,
              drilldown: "Interviewed Candidates",
            },
          ],
        },
      ],
      drilldown: {
        breadcrumbs: {
          position: {
            align: "right",
          },
        },
        series: [
          {
            name: "Employees",
            id: "Employees",
            data: [],
          },
          {
            name: "Firefox",
            id: "Firefox",
            data: [],
          },
          {
            name: "Interviewed Candidates",
            id: "Interviewed Candidates",
            data: [],
          },
        ],
      },
    };

    return (
      <div>
        <div className="banner-area text-center text-big top-pad-50">
          <div
            id="bootcarousel"
            className="carousel text-light slide animate_text"
            data-ride="carousel"
          >
            {/* Wrapper for slides */}
            <div className="carousel-inner carousel-zoom">
              <div className="carousel-item active">
                <div
                  className="slider-thumb bg-fixed"
                  style={{ backgroundImage: "url(assets/img/banner/5.jpg)" }}
                />
                <div className="box-table">
                  <div className="box-cell shadow dark-hard">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                          <div className="content">
                            <h2 data-animation="animated zoomInLeft">
                              Diverse <span>Inclusive Workplace</span>
                            </h2>
                            <p className="animated slideInRight">
                              Suchita Consulting is always help you on unlock
                              better team collaboration, communication, improved
                              innovation and increased financial revenue and
                              performance by great place to work.
                            </p>
                            <Link
                              to="/contact-us"
                              data-animation="animated zoomInUp"
                              className="btn btn-gradient effect btn-md"
                            >
                              Discover More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div
                  className="slider-thumb bg-fixed"
                  style={{ backgroundImage: "url(assets/img/banner/1.jpg)" }}
                />
                <div className="box-table">
                  <div className="box-cell shadow dark-hard">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                          <div className="content">
                            <h2 data-animation="animated slideInDown">
                              Recruitment <span>Pipeline</span>
                            </h2>
                            <p className="animated slideInRight">
                              We hire top skilled engineers. Earning great place
                              to work proves that you have created an excellent
                              employee experience and elevates employee status
                              as employer choice.
                            </p>
                            <Link
                              to="/contact-us"
                              data-animation="animated zoomInUp"
                              className="btn btn-gradient effect btn-md"
                            >
                              Discover More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Wrapper for slides */}
            {/* Left and right controls */}
            <a
              className="left carousel-control light"
              href="#bootcarousel"
              data-slide="prev"
            >
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="right carousel-control light"
              href="#bootcarousel"
              data-slide="next"
            >
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="about-content-area default-padding bg-gray">
          <div className="container">
            <div className="content-box">
              <div className="row">
                <div className="col-lg-12 info">
                  <h2>Make yourself the next Certification Success Story.</h2>
                  <div className="call">
                    <div className="icon">
                      <i className="flaticon-telephone" />
                    </div>
                    <div className="intro">
                      <h3>
                        {" "}
                        We finished it; now it's your time. Please get in touch
                        with us.
                      </h3>
                      <p className="mt-4">
                        Suchita Consulting certifications enable you to become
                        the IT professional that businesses are looking for.
                        Earning and maintaining certificates demonstrates that
                        you have the knowledge, aptitude, and lifelong learning
                        mindset that will place you ahead of the competition.
                      </p>
                    </div>
                  </div>
                  <div className="pt-5 mt-5 d-flex row">
                    <div className="col-12 col-sm-4">
                      {" "}
                      <div
                        data-iframe-width="350"
                        data-iframe-height="270"
                        data-share-badge-id="54a06c73-cfd4-49f0-b6eb-836fa27ca547"
                        data-share-badge-host="https://www.credly.com"
                      />
                    </div>
                    {/* <div className="col-12 col-sm-4">
                      {" "}
                      <div
                        data-iframe-width="350"
                        data-iframe-height="270"
                        data-share-badge-id="54a06c73-cfd4-49f0-b6eb-836fa27ca547"
                        data-share-badge-host="https://www.credly.com"
                      />
                    </div>
                    <div className="col-12 col-sm-4">
                      {" "}
                      <div
                        data-iframe-width="350"
                        data-iframe-height="270"
                        data-share-badge-id="54a06c73-cfd4-49f0-b6eb-836fa27ca547"
                        data-share-badge-host="https://www.credly.com"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-content-area default-padding">
          <div className="container">
            <div className="content-box">
              <div className="row">
                <div className="col-lg-6 thumb">
                  <div className="thumb-box">
                    <img src="assets/img/about/2.jpg" alt="Thumb" />
                    <img src="assets/img/about/3.jpg" alt="Thumb" />

                    <div
                      className="shape"
                      style={{ backgroundImage: "url(assets/img/shape/1.png)" }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 info">
                  <h2>
                    We Combine Technology <br /> with Business Ideas
                  </h2>
                  <ul>
                    <li>
                      <div className="icon">
                        <i className="ti-bookmark-alt" />
                      </div>
                      <div className="info">
                        <h4>Management View</h4>
                        <p>
                          Company Management has a clear view of where the
                          organization is going and how to get there.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="ti-id-badge" />
                      </div>
                      <div className="info">
                        <h4>Our Experience Employees</h4>
                        <p>
                          Suchita Consulting employees avoid politicking and
                          backstabbing as ways to get things done and also,
                          celebrate special events around here.
                        </p>
                      </div>
                    </li>
                  </ul>
                  <div className="call">
                    <div className="icon">
                      <i className="flaticon-telephone" />
                    </div>
                    <div className="intro">
                      <h5>8329551997</h5>
                      <span>Call to ask any question</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 center-info"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="partner-area shape-less overflow-hidden text-light">
          <div className="container">
            <div className="item-box">
              <div className="row align-center">
                <div className="col-lg-6 info">
                  <h2>
                    We're Trusted by <span>18+</span> <br /> Professional
                    Clients
                  </h2>
                  <p>
                    Our company mission is to build a better world by helping
                    other IT organizations become a great place to work for all
                    to fill their dreams.
                  </p>
                </div>
                <div className="col-lg-6 clients">
                  <ClientList />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Partner Area */}

        <ServicesList
          sectionheading="OUR SERVICES"
          sectiondes="What We Bring To You"
        />

        {/* Start Business Growth*/}
        <div className="business-groth-area default-padding bg-gray">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 info">
                <h4>Working with us?</h4>
                <h2>
                  It All Comes Down <br />
                  To Trust
                </h2>
                <p>
                  We provides an unparalleled opportunity to partner, clients
                  and who are work for us with some of the biggest brands, most
                  creative minds and inspiring leaders who sit at the forefront
                  of creating and maintaining innovative and successful
                  organizations.
                </p>

                <p>
                  We always welcome talented skilled engineers, high energetic,
                  decent team members to join our team and help us to create a
                  better society by helping other companies transform their
                  workplaces.
                </p>

                <Link
                  to="/contact-us"
                  className="btn btn-gradient effect btn-md"
                >
                  Reach us
                </Link>
              </div>
              <div className="col-lg-6 homepage">
                <HighchartsReact highcharts={Highcharts} options={options} />
              </div>
            </div>
          </div>
        </div>
        {/* End Business Growth */}

        {/* <WorksGallery/> */}

        <div className="business-groth-area default-padding bg-gray about-us-area">
          <div className="container about-items">
            <div className="row">
              <div className="col-lg-8 info">
                <img src="assets/img/about/6.jpg" />
              </div>
              <div className="col-lg-4 info">
                <h4>Dealing with clients</h4>
                <h2>
                  We follow <br />V - Modal
                </h2>
                <p>
                  <b>Industrial Challenge:</b> As the industry has evolved, Most
                  of the technologies have become more complex, faster, and
                  forever changing, however, there remains a set of basic
                  principles and concepts that are as applicable today as when
                  IT was in its infancy.
                </p>
                <ul>
                  <li>Accurately define and refine user requirements.</li>
                  <li>
                    Design and build an application according to the authorized
                    user requirements.
                  </li>
                  <li>
                    Validate that the application they had built adhered to the
                    authorized business requirements
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Start Contact Area */}
        <div className="contact-area default-padding bg-theme inc-shape">
          <div className="container">
            <div className="row align-center">
              <div className="col-lg-5 info">
                <div className="content">
                  <h2>Let's talk?</h2>
                  <p>
                    It's all about the humans behind a brand and those
                    experiencing it, we're right there. In the middle
                    performance quickly.
                  </p>
                  <ul>
                    <li>
                      <i className="fas fa-map-marker-alt" />
                      <p>
                        6701 Highway Blvd, <br />
                        Katy, Texas 77494
                      </p>
                    </li>
                    <li>
                      <i className="fas fa-phone" />
                      <p>8329551997</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 contact-form-box">
                <div className="form-box">
                  <form
                    action="assets/mail/contact.php"
                    method="POST"
                    className="contact-form"
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Name"
                            type="text"
                          />
                          <span className="alert-error" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Email*"
                            type="email"
                          />
                          <span className="alert-error" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            id="phone"
                            name="phone"
                            placeholder="Phone"
                            type="text"
                          />
                          <span className="alert-error" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group comments">
                          <textarea
                            className="form-control"
                            id="comments"
                            name="comments"
                            placeholder="Tell Us About Project *"
                            defaultValue={""}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <button type="submit" name="submit" id="submit">
                          Send Message <i className="fa fa-paper-plane" />
                        </button>
                      </div>
                    </div>
                    {/* Alert Message */}
                    <div className="col-lg-12 alert-notification">
                      <div id="message" className="alert-msg" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Contact Area */}

        <GenericBlog
          heading="Few Blogs from other source"
          des="Latest Articles"
        />
      </div>
    );
  }
}

export default Home;

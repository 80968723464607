import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ComingSoon from "../ComingSoon";

export default function CloudOps() {
  return (
    <div>
      {/* Start Breadcrumb 
  ============================================= */}
      <div
        className="no-breadcrumb breadcrumb-area text-center shadow dark text-light bg-cover"
        // style={{ backgroundImage: "url(assets/img/2440x1578.png)" , height: 150}}
      >
        {/* <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <h4>Drupal</h4>              
          </div>
        </div>
      </div> */}
      </div>
      {/* End Breadcrumb */}

      <div className="services-details-area default-padding pb-4">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-12 services-single-content">
                <h2>What do I need to know to be a cloudOps engineer?</h2>
                <p>
                  Welcome to the world of Cloud Operations, Computing or
                  CloudOps. Now, your entire architecture has been successfully
                  migrated to the cloudOps. Very Congratulations!
                </p>

                <p>
                  And, is this what the work ends? Not at all! Think, Just as an
                  on-prem setup requires continuous monitoring activity to keep
                  the wheels running, so does a cloud-based setup.
                </p>
                <p>
                  Because, nature of the cloud computing, CloudOps practices can
                  include an extra layers of complexity as compared to other IT
                  operations.
                </p>
              </div>
              <div className="col-12 services-sidebar">
                <img src="assets/img/list/cloud/1.png" alt="Thumb" />
              </div>
            </div>

            <div className="row default-padding pb-0">
              <div className="col-12 services-single-content pl-5">
                <h2>Responsibilities of a CloudOps Engineer?</h2>
                <p>
                  <b>Live Deployments:</b> A CloudOps engineer should be able to
                  provide deployment environments (on-prem/staging/Live) for various kinds of
                  applications as per the client and company requirements. 
                </p>
                <p>
                  <b>Dedicated Resource:</b> A CloudOps engineer should also know how to divide resource based on the project availability and distribute them as deploying resources, such as servers or network components when required.  
                </p>
                <p>
                  <b>Performance:</b> He should also check KPI's on the cloud computing, storage, network resources being run is a major responsibility of a CloudOps engineer. 
                </p>
                <p>
                  <b>Compliance:</b> If current organisation’s host country or their clients’ host countries might have compliance laws in place relating to the deployment and usage of cloud resources. 
                </p>
              </div>
              <div className="col-12 services-sidebar">
                <img src="assets/img/list/cloud/2.png" className="w-100 mt-5" alt="Thumb" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Star About Area
  ============================================= */}
      <div className="about-area bg-dark overflow-hidden text-light relative">
        {/* Fixed Shape */}
        <div
          className="fixed-shape"
          style={{ backgroundImage: "url(assets/img/shape/8.png)" }}
        />
        {/* End Fixed Shape */}
        <div className="container">
          <div className="about-items">
            <div className="row">
              <div
                className="col-lg-6 thumb order-lg-last"
                style={{ backgroundImage: "url(assets/img/list/cloud/3.png)" }}
              >
                <div className="successr-ate">
                  <div className="icon">
                    <i className="flaticon-target" />
                  </div>
                  <div className="content">
                    <h2>100%</h2>
                    <span>Success Rate</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 info">
                <h4>Who we are</h4>
                <h2>
                  We Combine Technology <br /> with Business Ideas
                </h2>
                <p>
                  Suchita Consulting is a leading IT solutions company that excels
                  in delivering Web development and android applications.
                </p>
                <p>
                  With an experience of 2+ years, we aim to deliver excellent
                  solutions to our genuine clients. We understand that future
                  world is rapidly shifting towards data-driven and AI, Machine
                  learning technologies.
                </p>
                <ul>
                  <li>
                    <h5>Innovate with perfection</h5>
                    <p>
                      Yes, You have landed on the right page. We are here to
                      help our client in every possible way.
                    </p>
                  </li>
                  <li>
                    <h5>Information Security Management</h5>
                    <p>
                      We prefer quality over quantity and that is what makes
                      Suchita Consulting one of the bests in the market. We are here
                      to help our client in every possible way.
                    </p>
                  </li>
                </ul>
                <Link to="/contact-us" className="btn btn-light effect btn-md">
                  Discover More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area */}
    </div>
  );
}

import React from "react";
import ComingSoon from "./ComingSoon";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ClientList from "../GenericComponents/ClientList";

export default function AboutUs() {
  const options = {
    chart: {
      type: "spline",
    },
    title: {
      text: "Company growth",
    },
    subtitle: {
      text: "Annual Report from starting to now",
    },
    plotOptions: {
      column: {
        depth: 25,
      },
    },
    xAxis: {
      labels: {
        skew3d: true,
        style: {
          fontSize: "16px",
        },
      },
    },
    yAxis: {
      title: {
        text: "Annual Growth",
      },
    },
    series: [
      {
        name: "Growth",
        data: [1520000, 10000000, 13000000, 14000000, 15850000],
      },
    ],
  };

  return (
    <div>
      {/* Start Breadcrumb 
    ============================================= */}
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{ backgroundImage: "url(assets/img/about/7.gif)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>About Us</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="#">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">About</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
      {/* Start About 
    ============================================= */}
      <div className="about-us-area default-padding bg-gray">
        <div className="container">
          <div className="about-items">            
            <div className="row align-center">
              <div className="col-lg-6">
                <div className="thumb">
                  <img src="assets/img/about/5.jpg" alt="Thumb" />
                  <h2>
                    <strong>2+</strong> years of <br /> working experience
                  </h2>
                </div>
              </div>
              <div className="col-lg-6 info">
                <h2>
                  We Combine Technology <br /> with Business Thoughts
                </h2>
                <p>
                  Suchita Consulting is a leading IT solutions company that excels
                  in delivering Web development and android applications.
                </p>
                <p>
                  With an experience of 2+ years, we aim to deliver excellent
                  solutions to our genuine clients. We understand that future
                  world is rapidly shifting towards data-driven and AI, Machine
                  learning technologies.
                </p>
                <p>
                  If you are also looking for a good team to help you with
                  data-driven decision making processes to enhance your client
                  base.
                </p>
                <p>
                  Yes, You have landed on the right page. We prefer quality over
                  quantity and that is what makes Suchita Consulting one of the
                  bests in the market. We are here to help our client in every
                  possible way.{" "}
                </p>
                <ul>
                  <li>Innovate with perfection</li>
                  <li>Information Security Management</li>
                  <li>Innovate thoughts</li>
                  <li>Analyze Client requirement</li>
                  <li>Organizational culture.</li>
                  <li>24/7 Live Support</li>
                </ul>
                <div className="author">
                  <div className="signature">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/jPv1ctxnmjQ" title="YouTube video player" allowFullScreen></iframe>
                  </div>
                  <div className="intro">
                    <h5>Arvind Thakore</h5>
                    <span>CEO &amp; Founder Suchita Consulting
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area */}


      {/* Star Work Process Area
    ============================================= */}
      <div className="work-process-area features-area default-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="site-heading text-center">
                <h4>See How It Works!</h4>
                <h2>Our Offerings</h2>
                <div className="devider" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="work-process-items features-content">
            <div className="row">
              {/* Single Item */}
              <div className="single-item col-lg-4 col-md-6">
                <div className="item">
                  <i className="flaticon-speech-bubble" />
                  <div className="top">
                    <span>01</span>
                    <h4>Employee Survey Platform</h4>
                  </div>

                  <p><img src="assets/img/about/8.png" className="custom-about-img"/></p>
                  <p>
                  This is the world's great employee experience platform built on 20+ years of research, proven to help you quantify our company culture, increase employee engagement and do the right thing for your business and people.
                  </p>
                  {/* <a href="#">
                    <i className="arrow_right" />
                  </a> */}
                </div>
              </div>
              {/* Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-4 col-md-6">
                <div className="item">
                  <i className="flaticon-label" />
                  <div className="top">
                    <span>02</span>
                    <h4>Great place to work</h4>
                  </div>
                  <p><img src="assets/img/about/9.png" className="custom-about-img"/></p>
                  <p>
                      Choose suitable work place for you and gain the opportunity to be featured in Great Place to Work technical blogs, case studies, social media, and more.
                  </p>
                  {/* <a href="#">
                    <i className="arrow_right" />
                  </a> */}
                </div>
              </div>
              {/* Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-4 col-md-6">
                <div className="item">
                  <i className="flaticon-file" />
                  <div className="top">
                    <span>03</span>
                    <h4>Respected Recognition</h4>
                  </div>
                  <p><img src="assets/img/about/10.png" className="custom-about-img"/></p>
                  <p>
                  We partner with leading IT Consultancy companies around the world to help your brand gain the best national/international exposure.
                  </p>
                  {/* <a href="#">
                    <i className="arrow_right" />
                  </a> */}
                </div>
              </div>
              {/* Single Item */}
            </div>
          </div>
        </div>
      </div>
      {/* End Work Process Area */}
      {/* Start Business Growth
    ============================================= */}
      <div className="business-groth-area default-padding bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 info">
              <h4>Suchita Consulting Analysis</h4>
              <h2>
                Annual Report <br />
                from starting to now
              </h2>
              <p>
                The financial statements are the most important part of the
                annual report that allows current and future investors,
                shareholders, employees and other business stakeholders to
                determine how well the company has performed in past, its
                ability to pay off its debts and its plans for growth.
              </p>
              <p>
                We're growing every year and increasing our assets in the
                company. We always deal with decent and skilled managers,
                business analyst and CEO.
              </p>
              <Link to="/contact-us" className="btn btn-gradient effect btn-md">
                Reach us
              </Link>
            </div>
            <div className="col-lg-6">
              <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
          </div>
        </div>
      </div>
      {/* End Business Growth */}
      {/* Star Partner Area
    ============================================= */}
      <div className="partner-area overflow-hidden bg-gray text-light">
        <div className="container">
          <div className="item-box">
            <div className="row align-center">
              <div className="col-lg-6 info">
                <h2>
                  We're Trusted by <span>18+</span> <br /> Professional
                  Clients
                </h2>
                <p>
                  It's possible to become a huge success with genuine clients and also, we have a great team in our organization.
                </p>
              </div>
              <div className="col-lg-6 clients">
                <ClientList />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Partner Area */}

      <div className="business-groth-area default-padding bg-gray about-us-area">
        <div className="container about-items">
          <div className="row">
            <div className="col-lg-8 info">
              <img src="assets/img/about/6.jpg" />
            </div>
            <div className="col-lg-4 info">
              <h4>Dealing with clients</h4>
              <h2>
                We follow <br />
                V - Modal
              </h2>
              <p>
                <b>Industrial Challenge:</b> As the industry has evolved, Most of the
                technologies have become more complex, faster, and
                forever changing, however, there remains a set of basic
                principles and concepts that are as applicable today as when IT
                was in its infancy.
              </p>
              <ul>
                <li>Accurately define and refine user requirements.</li>
                <li>
                  Design and build an application according to the authorized
                  user requirements.
                </li>
                <li>
                  Validate that the application they had built adhered to the
                  authorized business requirements
                </li>
              </ul>             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

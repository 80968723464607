import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";

export default function ServicesList(props: any) {

    const Img1 = require("../../assets/images/4.png");
    const Img2 = require("../../assets/images/5.png");
    const Img3 = require("../../assets/images/6.png");
    const Img4 = require("../../assets/images/7.png");
    const Img5 = require("../../assets/images/8.png");
    const Img6 = require("../../assets/images/9.png");

  const servicesList = [
    {
      title: "Cloud Computing",
      description: "Cloud computing is an application-based software infrastructure that stores data on remote serves, which can be accessed through the internet",
      src: Img4
    },
    {
      title: "Web application development",
      description: "A web application is application software that runs on a web server, unlike computer-based software programs that are run locally on the operating system",
      src: Img1
    },
    {
      title: "Android/iOS app development",
      description: "Developing Cross-Platform (Android/iOS) Apps Using a Single Codebase With Flutter or React-Native. With the combined App market on Google Play & Apple Store",
      src: Img2
    },
    {
      title: "Support & Maintainance",
      description: "Application support and maintenance services are aimed to ensure that applications are highly reliable, flexible and relevant to evolving business needs",
      src: Img3
    },    
    {
      title:
        "Product development",
        description: "Product managers act as the strategic directors of the development process. They communicate the big-picture goals and plans for the product",
        src: Img5
    },
    {
      title:
        "AR / VR and ML",
        description: "Augmented reality (AR) and Virtual Reality (VR) bridge the digital and physical worlds and allows you to take in information and content visually.",
        src: Img6
    },
  ];

  return (
    <div className="thumb-services-area inc-thumbnail default-padding bottom-less">
      <div className="right-shape">
        <img src="assets/img/shape/9.png" alt="Shape" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h4>{props.sectionheading}</h4>
              <h2>{props.sectiondes}</h2>
              <div className="devider" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="services-items text-center">
          <div className="row">
            {servicesList.map((data) => {
              return (
                <div className="col-lg-4 col-md-6 single-item">
                  <div
                    className="item"
                    style={{
                      backgroundImage: `linear-gradient(
                        325deg,
                        rgba(0, 36, 51, 1) 0%,
                        rgba(98, 218, 255, 0.164) 100%
                        ),url(${(data.src)})`
                    }}
                  >
                    <div className="info">
                      <i className="flaticon-analysis" />
                      <h4>{data.title}</h4>
                      <p>
                        {data.description}
                      </p>
                      {/* <div className="bottom">
                        <Link to="/services" className="text-danger text-capitalize">
                          <i className="fas fa-arrow-right" /> Read More
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

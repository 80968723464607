import React from "react";
import ourimpactIMG from "../../assets/images/ourimpactIMG.jpg";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import FeaturedCourses from "../../GenericComponents/FeaturedCourses";
import ComingSoon from "../ComingSoon";
import WebFrameList from "./Generic";

export default function FullStackDevelopment() {
  return (
    <div>
      {/* Start Breadcrumb 
    ============================================= */}
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{ backgroundImage: "url(assets/img/list/web-frame/5.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="py-5"></h1>
              <ul className="breadcrumb py-4">
                <li>
                  {/* <a href="#">
                    <i className="fas fa-home" /> Angular
                  </a> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
      {/* Star Services Details Area
    ============================================= */}
      <div className="services-details-area default-padding">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-lg-8 services-single-content">
                <h2>Full Stack Developer Skills You Need to Know</h2>
                <ul>
                  <li>
                    Full stack developer should be master of essential front-end
                    technologies like HTML5, CSS3, JavaScript. Knowledge of
                    third-party libraries like jQuery, LESS, Angular and ReactJS
                    is desirable
                  </li>
                  <li>
                    Full stack engineer should know at least one server-side
                    programming languages like Java, Python, Ruby, .Net etc.
                  </li>
                  <li>
                    Knowledge of various DBMS technology is another important
                    need of full stack developer. MySQL, MongoDB, Oracle,
                    SQLServer are widely used for this purpose. Knowledge of
                    caching mechanisms like varnish, Memcached, Redis is a plus.
                  </li>
                  <li>
                    In order to become a successful Full Stack web developer,
                    the knowledge of designing is also recommended. Moreover,
                    the person should know the principle of basic prototype
                    design and UI /UX design.
                  </li>
                  <li>
                    Exposure to handling Apache or nginx servers is desirable. A
                    good background in Linux helps tremendously in administering
                    servers.
                  </li>
                  <li>
                    A version control system allows full stack developers to
                    keep track of all the changes made in the codebase. The
                    knowledge of Git helps full stack developers to understand
                    how to get the latest code, update parts of the code, make
                    changes in other developer’s code without breaking things.
                  </li>
                  <li>
                    Knowledge of web services or API is also important for full
                    stack developers. Knowledge of creations and consumption of
                    REST and SOAP services is desirable.
                  </li>
                </ul>
                <img src="assets/img/list/web-frame/3.png" alt="Thumb" />

                <h2>Stackoverflow Survey of Developer profiles</h2>
                <img src="assets/img/list/web-frame/4.png" alt="Thumb" />
              </div>
              <div className="col-lg-4 services-sidebar">
                <WebFrameList />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Services Details Area */}
    </div>
  );
}

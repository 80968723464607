import React, { useState } from 'react';
import { AppContainer } from './styles';
import Header from './GenericComponents/Header';
import Footer from './GenericComponents/Footer';

function ApplicationEntry(Props: any) {

    return (
        <>
            <div className={'d-flex vh-100'}>
                <AppContainer className="main-content">
                    <Header />
                    <Footer />
                </AppContainer>
            </div>
        </>
    )
}

export default ApplicationEntry

import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ComingSoon from "../ComingSoon";

export default function Containers() {
  return (
    <div>
      {/* Start Breadcrumb 
      ============================================= */}
      <div
        className="no-breadcrumb breadcrumb-area text-center shadow dark text-light bg-cover"
        // style={{ backgroundImage: "url(assets/img/2440x1578.png)" , height: 150}}
      >
        {/* <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <h4>Drupal</h4>              
              </div>
            </div>
          </div> */}
      </div>
      {/* End Breadcrumb */}

      <div className="services-details-area default-padding pb-4">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-12 services-single-content">
                <h2>Understanding containers</h2>
                <ul>
                  <li>
                    Builder: a tool or series of tools used to build a
                    container, such as distrobuilder for LXC, or a Dockerfile
                    for Docker.
                  </li>
                  <li>
                    Engine: an application used to run a container. For Docker,
                    this refers to the docker command and the dockerd daemon.
                    For others, this can refer to the containerd daemon and
                    relevant commands (such as podman.)
                    <li>
                      Orchestration: technology used to manage many containers,
                      including Kubernetes and OKD.
                    </li>
                  </li>
                </ul>
              </div>
              <div className="col-12 services-sidebar">
                <img
                  src="assets/img/list/cloud/9.png"
                  className="mt-5"
                  alt="Thumb"
                />
              </div>
            </div>

            <div className="row default-padding pb-0">
              <div className="col-12 services-single-content pl-5">
                <h2> Why is there such interest in containers? </h2>
                <p>
                  Undoubtedly, one of the biggest reasons for recent interest in
                  container technology has been the Docker open source project,
                  a command line tool that made creating and working with
                  containers easy for developers and sysadmins alike, similar to
                  the way Vagrant made it easier for developers to explore
                  virtual machines easily.
                </p>
                <p>
                  Containers have also sparked an interest in microservice
                  architecture, a design pattern for developing applications in
                  which complex applications are broken down into smaller,
                  composable pieces which work together.
                </p>
                <p>
                  Each component is developed separately, and the application is
                  then simply the sum of its constituent components. Each piece,
                  or service, can live inside of a container, and can be scaled
                  independently of the rest of the application as the need
                  arises.
                </p>
              </div>

              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 services-sidebar">
                <img
                  src="assets/img/list/cloud/7.png"
                  className="mt-5"
                  alt="Thumb"
                />
              </div>
             
            </div>
          </div>
        </div>
      </div>

      {/* Star About Area
      ============================================= */}
      <div className="about-area bg-dark overflow-hidden text-light relative">
        {/* Fixed Shape */}
        <div
          className="fixed-shape"
          style={{ backgroundImage: "url(assets/img/shape/8.png)" }}
        />
        {/* End Fixed Shape */}
        <div className="container">
          <div className="about-items">
            <div className="row">
              <div
                className="col-lg-6 thumb order-lg-last"
                style={{ backgroundImage: "url(assets/img/shape/8.png)" }}
              >
                <div className="successr-ate">
                  <div className="icon">
                    <i className="flaticon-target" />
                  </div>
                  <div className="content">
                    <h2>100%</h2>
                    <span>Success Rate</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 info">
                <h4>Who we are</h4>
                <h2>
                  We Combine Technology <br /> with Business Ideas
                </h2>
                <p>
                  Suchita Consulting is a leading IT solutions company that excels
                  in delivering Web development and android applications.
                </p>
                <p>
                  With an experience of 2+ years, we aim to deliver excellent
                  solutions to our genuine clients. We understand that future
                  world is rapidly shifting towards data-driven and AI, Machine
                  learning technologies.
                </p>
                <ul>
                  <li>
                    <h5>Innovate with perfection</h5>
                    <p>
                      Yes, You have landed on the right page. We are here to
                      help our client in every possible way.
                    </p>
                  </li>
                  <li>
                    <h5>Information Security Management</h5>
                    <p>
                      We prefer quality over quantity and that is what makes
                      Suchita Consulting one of the bests in the market. We are here
                      to help our client in every possible way.
                    </p>
                  </li>
                </ul>
                <Link to="/contact-us" className="btn btn-light effect btn-md">
                  Discover More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area */}
    </div>
  );
}

import React from 'react';
import ourimpactIMG from '../../assets/images/ourimpactIMG.jpg';
import {
    Route,
    Link,
    Switch,
    Redirect
} from 'react-router-dom';
import FeaturedCourses from '../GenericComponents/FeaturedCourses';

export default function ComingSoon() {
    return (
        <div>
        {/* Breadcrumbs Start */}
        <div className="rs-breadcrumbs comingsoon aws-banner">
            <div className="container">
            <div className="breadcrumbs-inner">
                <h1 className="page-title">
                    Coming Soon...
                </h1>
            </div>
            </div>
        </div>
        {/* Breadcrumbs End */}
        </div>
    )
}
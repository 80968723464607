import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ComingSoon from "./ComingSoon";

export default function Careers() {
  return (
    <div>
      {/* Start Breadcrumb 
    ============================================= */}
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{ backgroundImage: "url(assets/img/banner/careers-banner.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>{' '}</h1>
              <ul className="breadcrumb">
              
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
      {/* Start Career 
    ============================================= */}
      <div className="custom-career default-padding bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ul className="job-list">
                <li>
                  <div className="fun-fact">
                    <div className="counter">
                      <div className="timer" data-to={45} data-speed={5000}>
                        45
                      </div>
                      <div className="operator">+</div>
                    </div>
                    <span className="medium">Jobs Posted</span>
                  </div>
                </li>
                
                <li>
                  <div className="fun-fact">
                    <div className="counter">
                      <div className="timer" data-to={50} data-speed={5000}>
                        50
                      </div>
                      <div className="operator">+</div>
                    </div>
                    <span className="medium">Active Employees</span>
                  </div>
                </li>

                <li>
                  <div className="fun-fact">
                    <div className="counter">
                      <div className="timer" data-to={42} data-speed={5000}>
                        42
                      </div>
                      <div className="operator">+</div>
                    </div>
                    <span className="medium">Happy Clients</span>
                  </div>
                </li>

              </ul>
            </div>
          </div>
          <div className="job-lists">
            <div className="row">
              <div className="col-lg-12">
                {/* Single Item */}
                <div className="item">
                  <div className="info">
                    <div className="top">
                      <i className="fab fa-wordpress" /> Developer
                    </div>
                    <h4>
                      Front-end web development{" "}
                      <span className="badge">Open</span>
                    </h4>
                    <ul>
                      <li>Job Type: Permanent</li>
                      <li>Vacancy: 5</li>
                    </ul>
                  </div>
                  {/* <div className="button">
                    <a className="btn btn-gradient effect btn-md" href="#">
                      Apply Now
                    </a>
                  </div> */}
                </div>
                {/* End Single Item */}
                {/* Single Item */}
                <div className="item">
                  <div className="info">
                    <div className="top">
                      <i className="fas fa-adjust" /> Designer
                    </div>
                    <h4>
                      Product Designer <span className="badge">Open</span>
                    </h4>
                    <ul>
                      <li>Job Type: Part Time</li>
                      <li>Vacancy: 8</li>
                    </ul>
                  </div>
                  {/* <div className="button">
                    <a className="btn btn-gradient effect btn-md" href="#">
                      Apply Now
                    </a>
                  </div> */}
                </div>
                {/* End Single Item */}
                {/* Single Item */}
                <div className="item">
                  <div className="info">
                    <div className="top">
                      <i className="fas fa-database" /> Application
                    </div>
                    <h4>
                      Senior web application developer{" "}
                      <span className="badge text-danger">Closed</span>
                    </h4>
                    <ul>
                      <li>Job Type: Permanent</li>
                      <li>Vacancy: 5</li>
                    </ul>
                  </div>
                  {/* <div className="button">
                    <a className="btn btn-gradient effect btn-md" href="#">
                      Apply Now
                    </a>
                  </div> */}
                </div>
                {/* End Single Item */}
                {/* Single Item */}
                <div className="item">
                  <div className="info">
                    <div className="top">
                      <i className="fab fa-wordpress" /> Developer
                    </div>
                    <h4>
                      Front-end web development{" "}
                      <span className="badge">Open</span>
                    </h4>
                    <ul>
                      <li>Job Type: Permanent</li>
                      <li>Vacancy: 5</li>
                    </ul>
                  </div>
                  {/* <div className="button">
                    <a className="btn btn-gradient effect btn-md" href="#">
                      Apply Now
                    </a>
                  </div> */}
                </div>
                {/* End Single Item */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Career */}
    </div>
  );
}

import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";

export default function ClientList() {
  return (
    <div className="partner-carousel owl-carousel owl-theme text-center">
      <div className="single-item">
        <a href="#">
          <img src="assets/img/clients/01.png" alt="Clients" />
        </a>
      </div>
      <div className="single-item">
        <a href="#">
          <img src="assets/img/clients/02.png" alt="Clients" />
        </a>
      </div>
      <div className="single-item">
        <a href="#">
          <img src="assets/img/clients/03.png" alt="Clients" />
        </a>
      </div>
      <div className="single-item">
        <a href="#">
          <img src="assets/img/clients/04.png" alt="Clients" />
        </a>
      </div>     
    
    </div>
  );
}

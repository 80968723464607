import React from "react";
import ourimpactIMG from "../../assets/images/ourimpactIMG.jpg";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import FeaturedCourses from "../../GenericComponents/FeaturedCourses";
import ComingSoon from "../ComingSoon";
import WebFrameList from "./Generic";

export default function Angular() {
  return (
    <div>
      {/* Start Breadcrumb 
    ============================================= */}
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{ backgroundImage: "url(assets/img/list/web-frame/1.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="py-5"></h1>
              <ul className="breadcrumb py-4">
                <li>
                  {/* <a href="#">
                    <i className="fas fa-home" /> Angular
                  </a> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
      {/* Star Services Details Area
    ============================================= */}
      <div className="services-details-area default-padding">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-lg-8 services-single-content">
                <img src="assets/img/list/web-frame/2.png" alt="Thumb" />
                <h2>You Should Know if You Want to Build a Digital Product</h2>
                <p>
                  Angular has gained huge popularity in recent years, and with
                  good reason. Let’s take a look at seven of the main benefits
                  of Angular, and what they mean for your business.
                </p>

                <p>
                  Angular has been on quite a journey. First introduced by
                  Google in 2009, this JavaScript-based framework for developing
                  mobile and desktop web applications is now a far cry from its
                  original incarnation. In 2016, Google completely rewrote and
                  re-released Angular to keep up with the increasing demands of
                  the web. Angular has gained huge popularity among developers.
                  In Stack Overflow’s 2019 Developer Survey, Angular was the
                  second most-used web framework by professional developers,
                  capturing 32.4 percent of the total.
                </p>
                <p>
                  Google continues to use Angular in a range of its own
                  applications, such as Google Cloud Platform and AdWords. The
                  platform has also been adopted by a host of big names, like
                  Samsung, Delta Air Lines, BMW, and Forbes.
                </p>
                {/* <div className="features">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="content">
                        <h4>Finance Restructuring</h4>
                        <p>
                          These cases are perfectly simple and easy to
                          distinguish. In a free hour, when our power of choice
                          is untrammelled.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="content">
                        <h4>International Markets</h4>
                        <p>
                          These cases are perfectly simple and easy to
                          distinguish. In a free hour, when our power of choice
                          is untrammelled.
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
                
                <h2>Angular Features</h2>
                <ul>
                  <li>Effective Cross-Platform Development.</li>
                  <li> High Quality of the Application.</li>
                  <li> Improved Speed and Performance.</li>
                  <li> Faster Development Process.</li>
                  <li>Readable and Testable Code.</li>
                  <li>More Lightweight Web Applications.</li>
                  <li>Efficient Problem-Solving Patterns.</li>
                  <li>Excellent Material Design Library.</li>
                </ul>

                <iframe  className="mt-5" width="50%" height="315" src="https://www.youtube.com/embed/k5E2AVpwsko" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

              </div>
              <div className="col-lg-4 services-sidebar">
                <WebFrameList />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Services Details Area */}
    </div>
  );
}

import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import GenericBlog from "../GenericComponents/GenericBlog";
import ComingSoon from "./ComingSoon";

export default function Blog() {
  return (
    <div>
      {/* Start Breadcrumb  */}
      <div
        className="breadcrumb-area shadow dark bg-cover text-center text-light"
        style={{ backgroundImage: "url(assets/img/banner/5.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1>Blog Standard</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="#">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li>
                  <a href="#">Blog</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
     
      <GenericBlog heading="Few Blogs from other source" des="Latest News and Articles"/>


    </div>
  );
}
